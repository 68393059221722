
import * as React from "react"
import PropTypes from "prop-types"

import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/common.scss";

import * as styles from "./layout.module.scss";

import LogoSVG from "../assets/logo.min.svg";
import SchleifeSVG from "../assets/schleife.svg";
import { useRef, useState } from "react"
import cogoToast from "cogo-toast"
import axios from "axios"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ children, apply }) => {

  const mailRef = useRef();
  const phoneRef = useRef();
  const descriptionRef = useRef();

  const [ sent, setSent ] = useState(false);

  return (
    <div className={styles.layout}>

      <div className="container bg-white">
        <div className="row">
          <div className="d-flex pt-4 justify-content-between align-items-center">
            <LogoSVG className={styles.logo} />

            <div className={styles.headerAddress + " d-none d-lg-block pr-4"}>
              <p>Edt am Stömerberg 2</p>
              <p>A-4673 Gaspoltshofen</p>
              <p>
                <a href={"https://www.stritzinger.at"} target={"_blank"}>
                  www.stritzinger.at
                </a>
              </p>
            </div>

          </div>

          <SchleifeSVG className={"mt-2"} />
        </div>
      </div>


      <div className={"container pt-4 bg-white " + styles.laoyutWrapper}>
        <div className="row">

          <div className="col-12">
            { children }
          </div>


          <div className={"mt-4 py-5 px-4 " + styles.applyForm}>
            <h1>Bewerben</h1>

            <p>Konnten wir Ihr Interesse wecken? Nennen Sie uns einfach Ihre Daten und wir melden uns bei Ihnen!</p>

            <form className={"mt-4"} id="contactForm">
              <div className="mb-3">
                <label htmlFor="mail" className="form-label">E-Mail Adresse</label>
                <input ref={mailRef} required type="email" className="form-control" id="mail" aria-describedby="emailHelp" />
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">Telefon</label>
                <input required ref={phoneRef} type="tel" className="form-control" id="phone" aria-describedby="emailHelp" />
              </div>

              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Über mich
                  <i>&nbsp;(optional)</i>
                </label>
                <textarea rows={10} className="form-control" id="description" ref={descriptionRef}
                          placeholder={"Beschreiben Sie sich kurz und knackig. Wie alt sind Sie? Ihre letzt abgeschlossene Schulbildung? Ihre letzten Arbeitgeber?"}
                />
              </div>


              {!sent &&
              <button className="mt-2 btn btn-stritz"
                      onClick={
                        async (e) => {
                          e?.preventDefault();
                          const mail = mailRef.current?.value;
                          const phone = phoneRef.current?.value;
                          const description = descriptionRef.current?.value;

                          const hasMail = (mail + "").trim().length > 1;
                          const hasPhone = (phone + "").trim().length > 1;

                          if (!hasMail && !hasPhone) {
                            cogoToast.warn(
                                "Achtung - bitte E-Mail oder Telefon hinterlegen",
                                {
                                  options: "top-center"
                                }
                            );

                            return;
                          }

                          try {
                            const data = { mail, phone, description, apply: apply };
                            await axios.post("https://iot-api.stritzinger.at/apply", data);

                            cogoToast.success(
                              "Ihre Bewerbung ist erfolgreich abgeschickt worden",
                              {
                                position: "top-center"
                              }
                            );

                            setSent(true);

                            if (window.fbq) {
                              window.fbq('track', 'Contact');
                            }


                          } catch (err) {
                            cogoToast.error(
                              "Leider ist ein unbekannter Fehler passiert. Bitte schicken Sie direkt eine Mail an office@stritzinger.at",
                              {
                                position: "top-center"
                              }
                            )
                          }

                        }
                      }
              >
                Jetzt absenden</button>
              }
            </form>

              { sent && <p>Ihre Bewerbung ist abgeschlossen. Vielen Dank!</p> }
          </div>

        </div>



      </div>

      <div className="container bg-black p-5">
        <div className="row">

          <div className="col-lg-4">
            <StaticImage src={"../images/buchstaben.jpg"} alt={"Buchstaben"} />
          </div>

          <div className="col-lg-4">
            <StaticImage className={"h-100"} objectFit={"cover"} src={"../images/pommes.jpg"} alt={"Buchstaben"} />
          </div>

          <div className="col-lg-4">
            <StaticImage className={"h-100"} objectFit={"cover"} src={"../images/schnitzel2.jpg"} alt={"Buchstaben"} />
          </div>

          <div className="col-12 mt-3">
            <p>
              <a href={"https://www.stritzinger.at/index.php/de/contact/impressum"} target={"_blank"}>Impressum</a>
            </p>
          </div>

        </div>
      </div>

    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
